import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PageLayout from '@global/page-layout';
import '@assets/styles/tailwind.css';

const PiCortexPage = () => {
  const data = useStaticQuery(graphql`
    query allPiCortexFeatureSectionJson {
      piCortexFeatureSectionJson {
        subTitle
        title
        sections {
          description
          image
          title
          features {
            description
            icon
            title
          }
        }
        mobile {
          title
          link
        }
      }
    }
  `);
  const { title, subTitle, sections, mobile } = data.piCortexFeatureSectionJson;
  return (
    <PageLayout title="B2B">
      <div className="bg-gray-100 pt-6">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
          <section className="text-gray-700 body-font">
            <div className="container px-5 py-24 mx-auto">
              <div className="text-center">
                <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
                  {title}
                </h1>
                <p className="leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                  {subTitle}
                </p>
              </div>
            </div>
          </section>
          {sections.map((section, index) => (
            <div
              key={index}
              className={
                index === 0
                  ? 'relative mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center'
                  : 'relative mt-12 sm:mt-16'
              }
            >
              {index === 0 ? (
                <>
                  <div className="relative">
                    <h4 className="text-2xl leading-8 font-medium text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                      {section.title}
                    </h4>
                    <p className="mt-3 text-lg leading-7 text-gray-500">
                      {section.description}
                    </p>
                    <ul className="mt-10" style={{ listStyleType: 'none' }}>
                      {section.features.map((feature, key) => (
                        <li className={key === 0 ? '' : 'mt-10'} key={key}>
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primaryColor text-white">
                                <svg
                                  className="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d={feature.icon}
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="ml-4">
                              <h5 className="text-lg leading-6 font-medium text-gray-900">
                                {feature.title}
                              </h5>
                              <p className="mt-2 text-base leading-6 text-gray-500">
                                {feature.description}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-10 -mx-4 relative lg:mt-0">
                    <img
                      className="relative mx-auto"
                      width={490}
                      src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1597403476/PiCortex%20Website/Operations_bc71dr.png"
                      alt=""
                    />
                  </div>
                </>
              ) : (
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="lg:col-start-2">
                    <h4 className="text-2xl leading-8 font-medium text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                      {section.title}
                    </h4>
                    <p className="mt-3 text-lg leading-7 text-gray-500">
                      {section.description}
                    </p>
                    <ul className="mt-10" style={{ listStyleType: 'none' }}>
                      {section.features.map((feature, key) => (
                        <li className={key === 0 ? '' : 'mt-10'} key={key}>
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-primaryColor text-white">
                                <svg
                                  className="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d={feature.icon}
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="ml-4">
                              <h5 className="text-lg leading-6 font-medium text-gray-900">
                                {feature.title}
                              </h5>
                              <p className="mt-2 text-base leading-6 text-gray-500">
                                {feature.description}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                    <img
                      className="relative mx-auto"
                      width={490}
                      src="https://res.cloudinary.com/dc3mzhqp1/image/upload/v1597405690/PiCortex%20Website/Loop_jonmyn.png"
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <section className="text-gray-700 body-font">
        <div className="container px-5 py-24 mx-auto flex items-center md:flex-row flex-col">
          <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
            <h2 className="text-xs text-primaryColor tracking-widest font-medium title-font mb-1">
              MANAGE ON THE GO
            </h2>
            <h1 className="md:text-3xl text-2xl font-medium title-font text-gray-900">
              Download our Android and iOS mobile applications
            </h1>
          </div>
          <div className="lg:flex flex-col lg:flex-row text-center md:text-left">
            {mobile.map((mob, id) => (
              <a
                key={id}
                href={mob.link}
                target="_blank"
                rel="noreferrer"
                className={`${
                  id > 0 ? 'ml-0 lg:ml-4' : 'ml-0'
                } bg-gray-200 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-300 focus:outline-none my-1 md:my-1 sm:my-4`}
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox={id === 0 ? '0 0 512 512' : '0 0 305 305'}
                  stroke="currentColor"
                >
                  {id === 0 ? (
                    <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                  ) : (
                    <>
                      <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                      <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
                    </>
                  )}
                </svg>
                <span className="ml-4 flex items-start flex-col leading-none">
                  <span className="title-font font-medium">{mob.title}</span>
                </span>
              </a>
            ))}
            <a
              href="https://appgallery.huawei.com/#/app/C103366867"
              target="_blank"
              rel="noreferrer"
              className="ml-0 lg:ml-4 bg-gray-200 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-300 focus:outline-none my-1 md:my-1 sm:my-4"
            >
              <span className="ml-4 flex items-start flex-col leading-none">
                <span className="title-font font-medium">
                  Huawei App Gallery
                </span>
              </span>
            </a>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default PiCortexPage;
